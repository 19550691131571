exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-submit-trial-form-tsx": () => import("./../../../src/pages/submit-trial-form.tsx" /* webpackChunkName: "component---src-pages-submit-trial-form-tsx" */),
  "component---src-pages-submit-tsx": () => import("./../../../src/pages/submit.tsx" /* webpackChunkName: "component---src-pages-submit-tsx" */),
  "component---src-pages-trial-form-tsx": () => import("./../../../src/pages/trial-form.tsx" /* webpackChunkName: "component---src-pages-trial-form-tsx" */)
}

